import type React from 'react';
import type { FocusableElement } from 'tabbable';
import { tabbable } from 'tabbable';

/**
 * Helper function to determine whether an element should receive focus.
 *
 * @param element the HTMLElement
 */
function isFocusable(element: Element): boolean {
  // eslint-disable-next-line unicorn/prefer-dom-node-dataset
  return element.hasAttribute('data-js-lia-tab-allowed');
}

/**
 * Function to disable keyboard events within the passed container reference,
 * while allowing and handling focus on elements with the attribute data-tab-allowed="true"
 *
 * @param event the focus event
 * @param containerReference a reference to the HTML element containing tabbable items
 */
export default function focusAllowedElementsHandler(
  event: React.FocusEvent,
  containerReference: React.MutableRefObject<HTMLElement>
): void {
  const { target, relatedTarget } = event;
  if (isFocusable(target)) {
    return null;
  } else if (containerReference.current) {
    let tabbableElements = tabbable(containerReference.current);
    let currentIndex = tabbableElements.indexOf(target as FocusableElement);

    /** Prevents elements which are not tabbable from triggering a focus somewhere else on the screen */
    if (currentIndex === -1) {
      return null;
    }

    const previousIndex = tabbableElements.indexOf(relatedTarget as FocusableElement);
    /**
     * Handle when the user uses shift-tab to go backwards in the tab order.
     * This allows us to still use `Array#find` and find the first tabbable element
     * before the currently tabbed element.
     */
    if (currentIndex < previousIndex) {
      tabbableElements = tabbableElements.reverse();
      currentIndex = tabbableElements.indexOf(target as FocusableElement);
    }
    let elementToFocus = tabbableElements
      .filter(element => isFocusable(element))
      .find(element => tabbableElements.indexOf(element) > currentIndex);
    elementToFocus = elementToFocus ?? tabbableElements[0];
    if (elementToFocus) {
      (elementToFocus as HTMLElement).focus();
    }
  }
  return null;
}

/**
 * Sets the focus on the first tabbable child element inside of the provided HTMLElement
 *
 * @param htmlElement the HTML element containing tabbable items
 * @param preventScroll the preventScroll .focus option
 */
export function focusFirstTabbableChildElement(htmlElement: HTMLElement, preventScroll = false) {
  if (htmlElement) {
    const tabbableElements: Element[] = tabbable(htmlElement);
    const [elementToFocus] = tabbableElements;
    if (elementToFocus) {
      (elementToFocus as HTMLElement).focus({ preventScroll });
    }
  }

  return null;
}

/**
 * @returns The list element that is highlighted in the suggestions list
 */
export function getActiveLineItem(
  listRef: React.MutableRefObject<HTMLElement>,
  highlightedItemClassName: string
): HTMLLIElement {
  return listRef.current.querySelector(`.${highlightedItemClassName}`);
}

/**
 * This function highlights a li element that has been navigated to via the keyboard
 * @param items The array of li elements in the suggestions list
 * @param index Index of the li element to be highlighted
 * @param highlightedItemClassName
 */
export function highlightElement(
  items: Array<Element>,
  index: number,
  highlightedItemClassName: string
): void {
  if (!(index > items.length - 1)) {
    items[index].classList.add(highlightedItemClassName);
    items[index].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
  }
}

/**
 * This function is used to remove the highlight from a li element
 * @param items The array of li elements in the suggestions list
 * @param index Index of the li element on which the highlight is to be removed
 * @param highlightedItemClassName
 */
export function removeElementHighlight(
  items: Array<Element>,
  index: number,
  highlightedItemClassName: string
): void {
  if (index >= 0) {
    items[index].classList.remove(highlightedItemClassName);
  }
}

/**
 * This function handles the keyboard navigation on the suggestions list
 * @param offset This a number that adds to the index from
 * the currently highlighted li element
 * @param listRef
 * @param listClassName
 * @param highlightedItemClassName
 */
export function setNextActiveElement(
  offset: number,
  listRef: React.MutableRefObject<HTMLElement>,
  listClassName: string,
  highlightedItemClassName: string
): void {
  if (!listRef.current) {
    return;
  }
  const items: Array<Element> = [...listRef.current.querySelectorAll(`li.${listClassName}`)];
  const activeElement = getActiveLineItem(listRef, highlightedItemClassName);
  const previousIndex = items.indexOf(activeElement);
  const possibleNextIndex = previousIndex + offset;
  const nextIndex = possibleNextIndex >= items.length ? 0 : possibleNextIndex;
  if (nextIndex < 0) {
    highlightElement(items, items.length - 1, highlightedItemClassName);
    removeElementHighlight(items, previousIndex, highlightedItemClassName);
    return;
  }
  highlightElement(items, nextIndex, highlightedItemClassName);
  removeElementHighlight(items, previousIndex, highlightedItemClassName);
}
