import React, { TouchEvent } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { SharedComponent } from '../../../enums';
import Icons from '../../../icons';
import Button from '../../common/Button/Button';
import { ButtonVariant } from '../../common/Button/enums';
import { IconColor, IconSize } from '../../common/Icon/enums';
import Icon from '../../common/Icon/Icon';
import useTranslation from '../../useTranslation';

interface Props {
  /**
   * Callback function invoked when the clear button is used
   */
  onClear: (event: React.TouchEvent | React.MouseEvent) => void;
  /**
   * Class name(s) to apply to the Button
   */
  className?: string;
  /**
   * Optional text override for the aria-label attribute
   */
  ariaLabel?: string;
}

/**
 * Renders a custom clear button for clearing the value in a react-select field or field using InputGroup
 *
 * @author Jonathan Bridges
 */
const ClearButton: React.FC<React.PropsWithChildren<Props>> = ({
  onClear,
  className,
  ariaLabel
}) => {
  const cx = useClassNameMapper();
  const { formatMessage, loading: textLoading } = useTranslation(SharedComponent.CLEAR_BUTTON);

  if (textLoading) {
    return null;
  }

  return (
    <Button
      className={cx('lia-g-icon-btn', className)}
      variant={ButtonVariant.NO_VARIANT}
      onTouchEnd={onClear}
      onClick={onClear}
      aria-label={ariaLabel ?? formatMessage('clear')}
      data-testid="ClearButton"
    >
      <Icon icon={Icons.CloseIcon} color={IconColor.GRAY_700} size={IconSize.PX_12} />
    </Button>
  );
};

export default ClearButton;
